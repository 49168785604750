import React from 'react';
import { useMedia } from 'react-use';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';

import Layout from '../components/Layout/Layout';
import HeroParticles from '../components/HeroParticles/HeroParticles';
import Section from '../components/Section/Section';
import IconButton from '../components/IconButton/IconButton';
import SimpleCard from '../components/Cards/SimpleCard';
import SimpleCardItem from '../components/Cards/SimpleCardItem';
import IconList from '../components/List/IconList';
import IconListItem from '../components/List/IconListItem';
import TableWrapper from '../components/Table/TableWrapper';
import TableHead from '../components/Table/TableHead';
import TableHeadItem from '../components/Table/TableHeadItem';
import TableBody from '../components/Table/TableBody';
import TableBodyRow from '../components/Table/TableBodyRow';
import TableBodyItem from '../components/Table/TableBodyItem';
import TableDropdown from '../components/Table/TableDropdown';
import TableDropdownItem from '../components/Table/TableDropdownItem';
import Faqs from '../components/Faqs/Faqs';
import FaqSingle from '../components/Faqs/FaqSingle';
import FloatingMenu from '../components/FloatingMenu/FloatingMenu';
import Seo from '../components/Seo';

import infoIcon1 from '../images/01-Patient-Portal-_ONC-Certified_.svg';
import infoIcon2 from '../images/02-Client-Branded-Mobile-App.svg';
import infoIcon3 from '../images/03-ProviderStaff-Mobile_App.svg';
import infoIcon4 from '../images/04-Customizable-Forms.svg';
import infoIcon5 from '../images/05-Virtual-Waiting-Room.svg';
import infoIcon6 from '../images/06-Live-Chat.svg';
import infoIcon7 from '../images/07-Value-Based-Care-Reporting.svg';
import infoIcon8 from '../images/08-Referral-Requests.svg';

const PatientEngagementPlatformsCompared = () => {
  const isDesktop = useMedia('(min-width: 1024px)', true);

  return (
    <Layout hasNavBg>
      <Seo
        title="Patient Engagement Platforms Compared | BridgeInteract"
        description="Compare leading patient engagement software options, including Bridge, Phreesia, and others. Discover the benefits of an all-in-one patient engagement solution"
        canonical="/patient-engagement-platforms-compared/"
      />

      <HeroParticles
        title="Future-Proof Your Patient Engagement With Our All-In-One Solution"
        subTitle="Manage the entire patient journey seamlessly anytime, anywhere, with the latest healthcare technology in patient engagement."
        label="Request a Demo Now"
        uri="/view-a-demo/"
      />

      <Section variant="no-pt-top">
        <Container>
          <Row className="align-items-center">
            <Col className="mb-5 mb-lg-0" lg={6}>
              <StaticImage
                src="../images/proven-ways-to-boost-patient-engagement2.jpg"
                alt=""
                className="rounded-xl"
              />
            </Col>
            <Col lg={6}>
              <p className="font-size-lg">
                Patient engagement is crucial in today’s healthcare environment.
                Efficiently managing the patient journey from start to finish
                not only improves patient satisfaction but also enhances
                operational efficiency. However, many healthcare providers face
                significant challenges when using multiple disparate systems to
                deliver care.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <FloatingMenu
        links={[
          'Choose A Complete & Cost-effective Solution',
          'Patient Engagement Solutions Compared',
          'BridgeInteract: Leading Health IT Innovation',
          'FAQs',
        ]}
      />

      <Section id="we-need-to-define-the-wording" variant="sticky">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder mb-0">
                Practitioners And Staff Members Often Struggle With The
                Following:
              </h2>
              <SimpleCard className="pt-5">
                <SimpleCardItem
                  col="4"
                  title="Increased complexity"
                  text="Juggling multiple programs can be confusing and time-consuming, leading to workflow inefficiencies and errors. Managing multiple vendors simultaneously can also add to operative challenges."
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Data fragmentation"
                  text="Patient information is scattered across different systems, making it difficult to access comprehensive and up-to-date records quickly."
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Higher costs"
                  text="Maintaining and training staff on several systems can be costly and resource-intensive."
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Reduced productivity"
                  text="Switching between platforms slows down daily operations and detracts from patient care."
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Limited integration"
                  text="Lack of seamless integration between systems can lead to communication breakdowns and delays in care delivery."
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Lack of patient adoption"
                  text="Complex user experience journeys or systems lead to low adoption by patients and staff, which increases administrative burden and the risk of potential errors."
                  className="mb-0 mb-lg-4"
                />
              </SimpleCard>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="section-2" variant="no-pt-top">
        <Container>
          <Row className="align-items-center">
            <Col className="pe-lg-5" lg={8}>
              <p className="font-size-sm">
                BridgeInteract is a complete patient engagement solution that
                addresses these pain points by offering an end-to-end platform
                that integrates all front- and back-end operations, ensuring a
                smooth and continuous patient experience.
              </p>
              <p className="font-size-sm">
                Consolidating all the products needed to deliver this experience
                effectively will enable you to:
              </p>
              <IconList>
                <IconListItem text="Reduce costs through thoughtful automation." />
                <IconListItem text="Improve staff efficiency and reduce administrative burden." />
                <IconListItem text="Consolidate your patient data and workflows into one platform fully using your EHR's functionality." />
                <IconListItem text="Increase patient satisfaction, acquisition, and retention by reaching them via mobile and desktop anytime, anywhere, with a comprehensive patient portal that manages their digital healthcare touchpoints." />
              </IconList>
              <p className="font-size-sm">
                <a href="/" className="text-decoration-underline">
                  BridgeInteract
                </a>{' '}
                is a modular, customizable patient engagement software suite
                that allows you to select the exact tools you need to enhance
                your operations, all smoothly integrated with your existing
                EHR/IT systems.
              </p>
              <p className="font-size-sm">
                We simplify your day-to-day operations so you can focus on what
                matters: improving your patient’s health outcomes
              </p>
            </Col>
            <Col className="my-4" lg={4}>
              <StaticImage
                src="../images/future-proof2.jpg"
                alt=""
                className="w-100 rounded-xl"
              />
            </Col>
          </Row>
        </Container>
      </Section>

      {isDesktop ? (
        <Section id="choose-a-complete-&-cost-effective-solution">
          <Container>
            <Row className="py-4">
              <Col>
                <h2 className="fw-bolder mb-5">
                  Choose A Complete & Cost-effective Solution
                </h2>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Billing"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Insurance Eligibility Verification"
                        text="Ability to collect patient insurance information and check patient insurance status from practice management system or directly from EDI, including co-pay amount."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Secure Online Bill Pay"
                        text="Ability for patients to pay bills online."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Copay Payments"
                        text="Patients can make copayments online during the check-in process."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Invoice and Payment History"
                        text="Access to past invoices incl. encounter detail and payment records."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Communication"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Broadcast Messaging"
                        text="Ability to send bulk messages (i.e. in-portal,SMS and email) to a cohort of patients based on filters."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Two-Way Communication (Patient-Provider)"
                        text="Ability for patients and providers/staff to securely communicate through a secure portal."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Recall Messaging"
                        text="Ability to send automated messages to patients to address gaps in care and/or recommended appointments."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Live Chat"
                        text="Ability to instant message via an website, app or SMS text between a patient and a provider/staff admin panel"
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Education"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Education"
                        text="Ability to send patient education to patients or provide a library of patient education to browse."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Forms"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Surveys and Reviews"
                        text="Ability for patients to complete surveys remotely via their own device."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Screeners"
                        text="Patient screeners and health risk assessments, including PHQ-9, GAD-7, CHAT, etc. integrated to EHR."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Form Builder"
                        text="Allows healthcare providers to create custom digital forms for patient use."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Intake"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Intake Software"
                        text="Ability to fill out check-in and/or intake/registration forms from patient's device or via tablet/kiosk."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Check-In Kiosk / Tablet"
                        text="Self-service kiosks or tablets for patients to check in at the healthcare facility."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Marketing"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Automated, Targeted Campaigns"
                        text="AI technology to automatically send messages (in-portal, SMS and email) to patients based a a pre-defined criteria."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Mobile"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Mobile App"
                        text="Mobile app (aka. Digital Front Door) for patients to access key patient information, incl. patient portal functionality. Published to Apple App and Google Play Store."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Client-Branded Mobile App"
                        text="Mobile app is client-branded."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Platform"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Homegrown Engagement Suite"
                        text="A suite of patient engagement tools."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Population Health Management"
                        text="Reporting for key patient data (ie. conditions, interactions, medications, etc.)."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Analytics and Reporting"
                        text="Platform analytics and reporting incl. audit logs."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Value-Based Care Reporting"
                        text="Reporting for key metrics related to value-based care, including patient engagement and quality measures."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Provider/Staff Mobile App"
                        text="A mobile application for healthcare providers and staff to manage patient portal-related functionality, including engaging in virtual visits."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Portal"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Portal (ONC Certified)"
                        text="ONC certified / CHPL-list web application featuring login area for patients to access their health information."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Dashboard"
                        text="Secure login area for patients to access their health information."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Referral Requests"
                        text="Ability for a patient to request a referral to a specialist through an electronic form."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Rx Refill Requests"
                        text="Ability of patients to electronically request refill prescriptions be forwarded to appropriate pharmacies."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Scheduling"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Online Scheduling Software"
                        text="Ability for patients to view and request appointments online without live staff assistance."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Automated Appointment Reminders"
                        text="Automatically-generated appointment reminders (SMS and/or email)."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Self Scheduling (incl. Custom Workflows and Website-based Scheduling)"
                        text="Ability for patients to self-schedule on a wide variety of appointment types supporting different scheduling workflows based on location, provider and appointment type."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              </Col>
            </Row>
            <Row className="py-4">
              <Col>
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Telehealth"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Telehealth Appointments"
                        text="Platform that facilitates interactions between patient and provider in lieu of face-to-face visits. Typically enables real-time video visits."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Video Visits"
                        text="Seemless integration of video conferencing for telehealth encounters."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Virtual Waiting Room"
                        text="Patients can wait for their appointments virtually."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
                <p className="font-size-sm mb-0 mt-2">
                  **This comparison reflects features stated by each provider in
                  their own websites and marketing materials as of September 25,
                  2024.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>
      ) : (
        <Section id="choose-a-complete-&-cost-effective-solution">
          <Container>
            <Row>
              <Col>
                <h2 className="fw-bolder mb-5">
                  Choose A Complete & Cost-effective Solution
                </h2>
                <TableDropdown className="mb-3" title="Billing">
                  <TableDropdownItem
                    title="Insurance Eligibility Verification"
                    text="Ability to collect patient insurance information and check patient insurance status from practice management system or directly from EDI, including co-pay amount."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Secure Online Bill Pay"
                    text="Ability for patients to pay bills online."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Copay Payments"
                    text="Patients can make copayments online during the check-in process."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Invoice and Payment History"
                    text="Access to past invoices incl. encounter detail and payment records."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Communication">
                  <TableDropdownItem
                    title="Broadcast Messaging"
                    text="Ability to send bulk messages (i.e. in-portal,SMS and email) to a cohort of patients based on filters."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Two-Way Communication (Patient-Provider)"
                    text="Ability for patients and providers/staff to securely communicate through a secure portal."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Recall Messaging"
                    text="Ability to send automated messages to patients to address gaps in care and/or recommended appointments."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Live Chat"
                    text="Ability to instant message via an website, app or SMS text between a patient and a provider/staff admin panel."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Education">
                  <TableDropdownItem
                    title="Patient Education"
                    text="Ability to send patient education to patients or provide a library of patient education to browse."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Forms">
                  <TableDropdownItem
                    title="Patient Surveys and Reviews"
                    text="Ability for patients to complete surveys remotely via their own device."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Screeners"
                    text="Patient screeners and health risk assessments, including PHQ-9, GAD-7, CHAT, etc. integrated to EHR."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Form Builder"
                    text="Allows healthcare providers to create custom digital forms for patient use."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Intake">
                  <TableDropdownItem
                    title="Patient Intake Software"
                    text="Ability to fill out check-in and/or intake/registration forms from patient's device or via tablet/kiosk."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Check-In Kiosk / Tablet"
                    text="Self-service kiosks or tablets for patients to check in at the healthcare facility."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center bg-secondary bg-opacity-10"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Marketing">
                  <TableDropdownItem
                    title="Automated, Targeted Campaigns"
                    text="AI technology to automatically send messages (in-portal, SMS and email) to patients based a a pre-defined criteria."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Mobile">
                  <TableDropdownItem
                    title="Mobile App"
                    text="Mobile app (aka. Digital Front Door) for patients to access key patient information, incl. patient portal functionality. Published to Apple App and Google Play Store."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Client-Branded Mobile App"
                    text="Mobile app is client-branded."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Platform">
                  <TableDropdownItem
                    title="Homegrown Engagement Suite"
                    text="A suite of patient engagement tools."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Population Health Management"
                    text="Reporting for key patient data (ie. conditions, interactions, medications, etc.)."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Analytics and Reporting"
                    text="Platform analytics and reporting incl. audit logs."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Value-Based Care Reporting"
                    text="Reporting for key metrics related to value-based care, including patient engagement and quality measures."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Provider/Staff Mobile App"
                    text="A mobile application for healthcare providers and staff to manage patient portal-related functionality, including engaging in virtual visits."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Portal">
                  <TableDropdownItem
                    title="Patient Portal (ONC Certified)"
                    text="ONC certified / CHPL-list web application featuring login area for patients to access their health information."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Patient Dashboard"
                    text="Secure login area for patients to access their health information."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Referral Requests"
                    text="Ability for a patient to request a referral to a specialist through an electronic form."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Rx Refill Requests"
                    text="Ability of patients to electronically request refill prescriptions be forwarded to appropriate pharmacies."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Scheduling">
                  <TableDropdownItem
                    title="Online Scheduling Software"
                    text="Ability for patients to view and request appointments online without live staff assistance."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Automated Appointment Reminders"
                    text="Automatically-generated appointment reminders (SMS and/or email)."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Patient Self Scheduling (incl. Custom Workflows and Website-based Scheduling)"
                    text="Ability for patients to self-schedule on a wide variety of appointment types supporting different scheduling workflows based on location, provider and appointment type."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
                <TableDropdown className="mb-3" title="Telehealth">
                  <TableDropdownItem
                    title="Telehealth Appointments"
                    text="Platform that facilitates interactions between patient and provider in lieu of face-to-face visits. Typically enables real-time video visits."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Video Visits"
                    text="Seemless integration of video conferencing for telehealth encounters."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Virtual Waiting Room"
                    text="Patients can wait for their appointments virtually."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>

                <p className="font-size-sm mb-0 mt-2">
                  **This comparison reflects features stated by each provider in
                  their own websites and marketing materials as of September 25,
                  2024.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>
      )}

      <Section id="patient-engagement-solutions-compared">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder">
                Patient Engagement Solutions Compared
              </h2>
              <p className="font-size-sm mb-0">
                BridgeInteract stands out by providing a fully integrated system
                that covers the entire patient journey. Unlike competitors who
                specialize in different aspects of patient engagement, our
                solution offers a{' '}
                <a
                  href="/platform-features/"
                  className="text-decoration-underline"
                >
                  comprehensive suite of tools
                </a>{' '}
                all in one place.
              </p>
            </Col>
          </Row>
          <Row className="py-5">
            <Col className="mb-4 mb-lg-0" lg={4}>
              <div className="h-100 border rounded-xs shadow p-4">
                <h4 className="fw-bolder mb-2">Phreesia®</h4>
                <p className="font-size-sm mb-0">
                  Phreesia is a patient intake and registration platform
                  designed to streamline the administrative processes in
                  healthcare facilities. However, the platform’s complexity can
                  be overwhelming for smaller practices, and the initial setup
                  and integration with existing systems can be time-consuming
                  and costly. Additionally, while Phreesia offers a wide range
                  of functionalities, some users might find its customization
                  options limited compared to other, more flexible solutions.
                </p>
              </div>
            </Col>
            <Col className="mb-4 mb-lg-0" lg={4}>
              <div className="h-100 border rounded-xs shadow p-4">
                <h4 className="fw-bolder mb-2">InteliChart®</h4>
                <p className="font-size-sm mb-0">
                  InteliChart focuses on patient engagement and population
                  health management through its patient portal, outreach, and
                  population health tools. However, Intelichart may struggle to
                  handle complex clinical workflows or extensive integration
                  needs. Notably, while InteliChart offers a generic patient
                  portal app, it does not take full advantage of the mobile
                  platform by offering custom client-branded apps. Some user
                  reviews have also commented that Intelichart’s customer
                  support could be more responsive and proactive in addressing
                  technical issues. *
                </p>
              </div>
            </Col>
            <Col className="mb-4 mb-lg-0" lg={4}>
              <div className="h-100 border rounded-xs shadow p-4">
                <h4 className="fw-bolder mb-2">Relatient®</h4>
                <p className="font-size-sm mb-0">
                  Relatient specializes in patient communication and engagement,
                  offering solutions such as appointment reminders, patient
                  surveys, and health campaigns. However, one downside is that
                  Relatient’s focus on communication may mean it lacks some of
                  the more comprehensive features found in broader health IT
                  platforms, such as detailed reporting and analytics or
                  extensive patient intake functionalities. Additionally, some
                  users might find the customization of templates and messages
                  somewhat limited, potentially impacting the personalization of
                  patient communications.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <i>
                  Disclaimer:{' '}
                  <span className="text-secondary">
                    [Relatient®, Phreesia®, and InteliChart®]
                  </span>{' '}
                  and their derivatives are registered trademarks of{' '}
                  <span className="text-secondary">
                    Relatient, Phreesia, and InteliChart, respectively
                  </span>
                  , which are not affiliated with our service. Competitive
                  comparison information on our website is presented in good
                  faith and believed to be up-to-date and accurate and based
                  upon their then-current marketing materials. If you notice any
                  errors or out-of-date statements please email our support
                  address and let us know.
                </i>
              </p>

              <p>
                <i>
                  *Intelichart.com. (2022). Buyers Guide: Comparing Patient
                  Engagement Technology. Available at:{' '}
                  <a
                    href="https://intelichart.com/hubfs/2022-11-CL-Intelichart-VendorComparison-v2.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Link
                  </a>
                  . Accessed: 25 September 2024.
                </i>
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="bridgeinteract:-leading-health-it-innovation">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder">
                BridgeInteract: Leading Health IT Innovation
              </h2>
            </Col>
          </Row>
          <Row id="value-based-care" className="align-items-center mt-4">
            <Col className="pe-lg-5" lg={6}>
              <p className="font-size-sm">
                While competing patient engagement software platforms may be
                lacking in one or more key areas, BridgeInteract’s comprehensive
                suite of digital tools is engineered to be an all-in-one patient
                engagement solution, making it the go-to choice for modern
                healthcare providers. Clients can choose which modules and
                add-ons to use, ensuring a solution that covers all their needs
                without wasting money on unnecessary features.
              </p>
              <p className="font-size-sm">
                In addition to common features of patient engagement software
                solutions, BridgeInteract goes above and beyond the competition
                by offering the following features:
              </p>
            </Col>
            <Col lg={6}>
              <StaticImage
                src="../images/targeted-engagement2.jpg"
                alt=""
                className="rounded-xl mb-5 mb-lg-0"
              />
            </Col>
          </Row>
          <SimpleCard className="my-5">
            <SimpleCardItem
              col="6"
              title="Patient Portal (ONC Certified)"
              text="Our secure and intuitive portal provides patients with access to their personal health records, lab results, and medical history, enabling them to make informed decisions. The certification ensures compliance with health data standards and regulations, enhancing trust and safety for both patients and providers."
              icon={infoIcon1}
              className="mb-4"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Client-Branded Mobile App"
              text="Publish your own branded mobile app to give patients convenient access to healthcare services from their smartphones, extend your reach, and reinforce your brand identity with a consistent look and feel. Features like appointment scheduling, bill payment, and medical records are all optimized for user engagement and satisfaction."
              icon={infoIcon2}
              className="mb-4"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Provider/Staff Mobile App"
              text="BridgeInteract enhances provider efficiency with a dedicated mobile app designed for staff and healthcare professionals. This tool allows for real-time access to patient information and health records, facilitating seamless communication within healthcare teams to improve coordination and response times in patient care."
              icon={infoIcon3}
              className="mb-4"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Customizable Forms"
              text="Our customizable form builder feature allows healthcare providers to create and tailor forms according to specific needs, from intake and consent forms to health surveys and screening questionnaires. Gather the precise information necessary for effective patient management and care planning, all while streamlining the data collection process."
              icon={infoIcon4}
              className="mb-4"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Virtual Waiting Room"
              text="BridgeInteract's virtual waiting room transforms the way patients wait for their appointments. Reduce physical waiting room congestion and enhance patient experience by providing educational content, real-time updates on wait times, and appointment alerts."
              icon={infoIcon5}
              className="mb-4"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Live Chat"
              text="Enable immediate communication between patients and healthcare providers. Provide real-time support to quickly address patient concerns and enhance patient satisfaction. Whether for general inquiries or specific medical questions, live chat ensures that patients receive the assistance they need promptly and efficiently."
              icon={infoIcon6}
              className="mb-4"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Value-Based Care Reporting"
              text="BridgeInteract supports value-based care initiatives through comprehensive reporting tools that track and analyze patient outcomes, provider performance, and care quality. Improve care delivery, optimize patient health outcomes, and comply with value-based care models."
              icon={infoIcon7}
              className="mb-4 mb-lg-0"
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="Referral Requests"
              text="Patients can request referrals through the portal or app, reducing wait times and administrative burdens while improving communication between primary care providers and specialists. Enhance the continuity of care, ensuring that patients receive the specialized treatment they need without unnecessary delays."
              icon={infoIcon8}
              showIcon
            />
          </SimpleCard>
          <Row>
            <Col className="d-flex justify-content-center pt-lg-3">
              <IconButton className="c-icon-button__big" href="/view-a-demo/">
                Experience the Difference – Schedule a Demo Today!{' '}
                <FaArrowRight size={16} className="d-none d-lg-block ms-lg-2" />
              </IconButton>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="faqs">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">FAQs</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Faqs className="mb-5">
                <FaqSingle question="How does BridgeInteract improve patient engagement?">
                  <p className="font-size-sm">
                    BridgeInteract enhances patient engagement by providing a
                    comprehensive suite of digital tools that facilitate easy
                    access to healthcare services.{' '}
                    <a
                      href="/patient-portal-software/"
                      className="text-decoration-underline"
                    >
                      Our patient portal
                    </a>{' '}
                    allows patients to manage their health information, schedule
                    appointments, communicate with healthcare providers, and
                    access educational resources, all from one platform.
                    Additionally, features like live chat and mobile apps
                    improve communication and accessibility, making healthcare
                    management more proactive and patient-centered.
                  </p>
                </FaqSingle>
                <FaqSingle question="What makes your solution different from competitors?">
                  <p className="font-size-sm">
                    BridgeInteract stands out due to its seamless integration
                    capabilities, customizable features, and comprehensive
                    patient engagement tools. Unlike many competitors,
                    BridgeInteract offers a fully integrated system that
                    supports both provider and patient needs, including
                    customizable forms, value-based care reporting, and a
                    client-branded mobile app. Our platform is designed to be
                    flexible, accommodating the unique workflows of different
                    healthcare providers and enhancing both operational
                    efficiency and patient experience.
                  </p>
                </FaqSingle>
                <FaqSingle question="Can BridgeInteract integrate with our existing EHR?">
                  <p className="font-size-sm">
                    Yes, BridgeInteract can integrate seamlessly with existing
                    Electronic Health Records (EHR) systems. Our platform
                    supports various standard healthcare data exchange
                    protocols, such as HL7, FHIR, and REST API, ensuring that it
                    can easily connect with and extend the functionality of your
                    current EHR system for a unified view of patient data.
                  </p>
                </FaqSingle>
                <FaqSingle question="Can I have my brand in the mobile app?">
                  <p className="font-size-sm">
                    Absolutely.{' '}
                    <a
                      href="/patient-engagement-mobile-app/"
                      className="text-decoration-underline"
                    >
                      BridgeInteract’s mobile app
                    </a>{' '}
                    can be fully branded to reflect your healthcare
                    organization’s logo, color scheme, and other brand elements.
                    This feature helps maintain brand consistency across all
                    patient touchpoints, enhancing your organization’s
                    visibility and reinforcing brand loyalty among your
                    patients.
                  </p>
                </FaqSingle>
                <FaqSingle question="Does BridgeInteract process copayments?">
                  <p className="font-size-sm">
                    Yes, BridgeInteract includes functionality for processing
                    copayments. Our platform facilitates{' '}
                    <a
                      href="/patient-payments/"
                      className="text-decoration-underline"
                    >
                      secure and convenient payment options for patients
                    </a>
                    , supporting various forms of payment, including credit
                    cards, debit cards, and mobile payment systems. This lets
                    patients pay their copays and other medical bills directly
                    through the app or patient portal, simplifying the payment
                    process.
                  </p>
                </FaqSingle>
                <FaqSingle question="How do I save costs using technology?">
                  <p className="font-size-sm">
                    By implementing BridgeInteract, healthcare providers can
                    significantly reduce operational costs. Automating
                    scheduling, reminders, patient intake, and billing processes
                    decreases the need for manual intervention and lowers
                    administrative overhead. Additionally, features like virtual
                    waiting rooms and telehealth services can reduce physical
                    space requirements and optimize staff allocation, further
                    cutting costs while maintaining high levels of patient
                    satisfaction.
                  </p>
                </FaqSingle>
                <FaqSingle question="Can I customize patient engagement platforms?">
                  <p className="font-size-sm">
                    Yes, with BridgeInteract, you can customize your patient
                    engagement platform to meet the specific needs of your
                    healthcare practice. Our platform allows you to tailor
                    features such as forms, communication methods, reporting,
                    and patient-facing interfaces. This customization capability
                    ensures the platform works effectively within your existing
                    workflows and meets your specific engagement goals.
                  </p>
                </FaqSingle>
              </Faqs>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default PatientEngagementPlatformsCompared;
