import React from 'react';
import { string, bool, oneOfType, node } from 'prop-types';
import cx from 'classnames';
import Col from 'react-bootstrap/Col';

const propTypes = {
  col: string.isRequired,
  title: string.isRequired,
  text: oneOfType([string, node]).isRequired,
  icon: string,
  iconRight: string,
  className: string,
  showTitle: bool,
  showIcon: bool,
  showIconRight: bool,
};

const defaultProps = {
  icon: undefined,
  iconRight: undefined,
  className: undefined,
  showTitle: true,
  showIcon: false,
  showIconRight: false,
};

const SimpleCardItem = ({
  col,
  title,
  text,
  icon,
  iconRight,
  className,
  showTitle,
  showIcon,
  showIconRight,
}) => {
  const colClasses = cx(className);
  const cardClasses = cx(
    'c-simple-card-item position-relative d-flex h-100 shadow rounded-xs',
    {
      'align-items-start gap-4 gap-lg-5 p-4 p-lg-5': showIcon,
      'flex-column justify-content-center p-3 has-border': !showIcon,
      'align-items-center justify-content-between gap-5 p-4 has-border-top':
        showIconRight,
    },
  );

  return (
    <Col className={colClasses} xl={col}>
      <div className={cardClasses}>
        {showIcon && <img src={icon} alt="" />}
        <div>
          {showTitle && <h4 className="fw-bold mb-2">{title}</h4>}
          {text && <p className="text-gray-700 mb-0">{text}</p>}
        </div>
        {showIconRight && (
          <img src={iconRight} alt="" className="d-none d-md-inline-block" />
        )}
      </div>
    </Col>
  );
};

SimpleCardItem.propTypes = propTypes;
SimpleCardItem.defaultProps = defaultProps;

export default SimpleCardItem;
